import React, { useState } from "react";
import {post} from '../services/apiService';
import { Link, useNavigate } from "react-router-dom";

function Register() {
  const [userType, setUserType] = useState('buyer');
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials(prevcredentials => ({
      ...prevcredentials,
      [name]: value
    }));
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setErrorMessage('');
    setIsLoading(true);
    if (!credentials.email || !credentials.password || !credentials.firstName || !credentials.lastName || !credentials.phone) {
      setErrorMessage('Veuillez remplir tous les champs.');
      setIsLoading(false);
      return;
    }
    try {
      const response = await post('/register', credentials);
      navigate('/check-email', { state: { email: response.email, fromLogin: false } });
      setIsLoading(false);
    } catch (error) {console.log(error.response.data)
      setErrorMessage(error.response.data[Object.keys(error.response.data)[0]] || 'Une erreur est survenue.');
      setIsLoading(false);
    }
  };

  return (
    <form className="px-12 py-6" onSubmit={handleRegister}>
      <div className="text-center">Vous êtes vendeur? <Link className="text-blue-500 underline" to="/inscription-vendeurs">Inscrivez-vous ici</Link></div>
      {errorMessage && <div className="text-red-500 font-semibold bg-red-100 px-3 py-2">{errorMessage}</div>}
      <label htmlFor="email" className="block text-sm font-medium text-gray-700 py-3">Email</label>
      <input type="email" name="email" value={credentials.email} onChange={handleChange} id="email" className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
      <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 py-3">Prénom</label>
      <input type="text" id="firstName" name="firstName" value={credentials.firstName} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
      <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 py-3">Nom</label>
      <input type="text" id="lastName" name="lastName" value={credentials.lastName} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
      <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 py-3">Numéro de téléphone</label>
      <input type="text" id="phone" name="phone" value={credentials.phone} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
      <label htmlFor="password" className="block text-sm font-medium text-gray-700 py-3 mt-6">Mot de passe</label>
      <input type="password" id="password" name="password" value={credentials.password} onChange={handleChange} className="mt-1 px-6 py-5 block w-full border border-gray-300 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
      {/* <div className="mt-4 radio-buttons">
        <label className="inline-flex items-center radio">
          <input type="radio" name="userType" value="buyer" checked={credentials.userType === 'buyer'} onChange={() => credentials.userType = 'buyer'} className="text-blue-600" />
          <span className="ml-2">Je suis un acheteur</span>
        </label>
        </div>
        <div className="radio-buttons">
        <label className="inline-flex items-center ml-6 radio">
          <input type="radio" name="userType" value="seller" checked={credentials.userType === 'seller'} onChange={() => credentials.userType = 'seller'} className="text-blue-600" />
          <span className="radio-mark">Je veux créer ma boutique</span>
        </label>
      </div> */}
      <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded w-full py-4 mt-4 submit" disabled={isLoading}>
        {isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : 'Inscription'}
      </button>

      <div className="text-center w-full mt-3">
        <a href="#login" className="underline items-center mt-3">Vous avez déjà un compte ? Connectez-vous</a>
      </div>
    </form>
  );
}

export default Register;
