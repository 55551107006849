import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const ErrorAnimation = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center my-24 py-24">
      <div className="transition-transform duration-300 transform scale-100 flex flex-col items-center bg-white p-8 rounded-lg">
        <div className="flex items-center justify-center w-32 h-32 rounded-full bg-red-100 mb-4 slide-in">
          <CheckIcon className="h-24 w-24 text-red-500" />
        </div>
        <h2 className="text-2xl font-bold text-red-600 mb-4">Erreur de Paiement</h2>
        <p className="text-gray-700 mb-4">
          Nous sommes désolés, mais votre transaction n’a pas pu être complétée. Veuillez vérifier les informations suivantes :
        </p>
        <ul className="list-disc list-inside text-gray-700 mb-4 space-y-2">
          <li>Vérifiez que votre compte dispose de suffisamment de fonds pour l'achat.</li>
          <li>Contactez votre l'organisme de paiement pour voir si vous avez les droits.</li>
        </ul>
        <p className="text-gray-700 mb-4">
          Si le problème persiste, veuillez réessayer plus tard ou utiliser un autre moyen de paiement. Pour toute assistance, contactez notre service client.
        </p>
        <button
          onClick={() => navigate('/checkout')}
          className="w-full bg-red-600 text-white font-semibold py-2 rounded-md hover:bg-red-700 transition-colors"
        >
          Réessayer le paiement
        </button>
      </div>
    </div>
  );
};

export default ErrorAnimation;
