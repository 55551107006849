import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { post } from '../services/apiService';
import { Helmet } from 'react-helmet-async';

function RegisterSeller() {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    shopName: '',
    phone: '',
    address: '',
    town: ''
  });
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setCredentials(prevcredentials => ({
      ...prevcredentials,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validate = () => {
    const newErrors = {};
  
    if (!credentials.firstName) newErrors.firstName = 'le prénom est requis';
    if (!credentials.lastName) newErrors.lastName = 'le nom  est requis';
    if (!credentials.email) newErrors.email = 'L\'adresse email est requise';
    if (!credentials.password) newErrors.password = 'Le mot de passe est requis';
    if (!credentials.phone) {
      newErrors.phone = 'Le numéro de téléphone est requis';
    } else if (!/^7[05678]\d{7}$/.test(credentials.phone)) {
      newErrors.phone = 'Le numéro n\'est pas valide';
    }
    if (!credentials.shopName) newErrors.shopName = 'Le nom de la boutique est requis';
    if (!credentials.address) newErrors.address = 'L\'adresse est requise';
    if (!credentials.town) newErrors.town = 'La ville est requise';
    if (!credentials.agreeToTerms) newErrors.agreeToTerms = 'Veuillez accepter les termes et conditins d\'achat et de vente';
  
    setErrors(newErrors);
  
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (validate()) {
      try {
        const response = await post('/sellers/register', credentials);
        navigate('/check-email', { state: { email: response.email, fromLogin: false } });
        setIsLoading(false);
      } catch (error) {
        setErrors(error.response.data[Object.keys(error.response.data)[0]] || 'Une erreur est survenue.');
        setIsLoading(false);
      }
    }else
      setIsLoading(false);
  };

    return (
      <div className="mx-auto xl:w-1/4 lg:w-1/2 md:w-1/2 w-full h-full shadow-xl flex pt-12 mb-12">
        <Helmet>
          <title>Inscription pour les vendeurs - DeffCi</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="w-full shadow">
          <div className="flex items-center w-full justify-center">
            <Link to="/"><img className="lg:w-44 w-36" src="/logo.jpeg" alt="" /></Link>
          </div>
          <div className="text-center text-xl">Inscription vendeurs</div>
          <form className="px-12 py-6" onSubmit={handleRegister}>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 py-3">Email</label>
            <input type="email" name="email" value={credentials.email} onChange={handleChange} id="email" className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.email && <span className="text-sm text-red-600">{errors.email}</span>}

            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 py-3">Prénom</label>
            <input type="text" id="firstName" name="firstName" value={credentials.firstName} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.firstName && <span className="text-sm text-red-600">{errors.firstName}</span>}

            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 py-3">Nom</label>
            <input type="text" id="lastName" name="lastName" value={credentials.lastName} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.lastName && <span className="text-sm text-red-600">{errors.lastName}</span>}

            <label htmlFor="shopName" className="block text-sm font-medium text-gray-700 py-3">Nom de la boutique</label>
            <input type="text" id="shopName" name="shopName" value={credentials.shopName} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.shopName && <span className="text-sm text-red-600">{errors.shopName}</span>}

            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 py-3">Numéro de téléphone</label>
            <input type="text" id="phone" name="phone" value={credentials.phone} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.phone && <span className="text-sm text-red-600">{errors.phone}</span>}

            <label htmlFor="address" className="block text-sm font-medium text-gray-700 py-3">Adresse</label>
            <input type="text" id="address" name="address" value={credentials.address} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.address && <span className="text-sm text-red-600">{errors.address}</span>}

            <label htmlFor="town" className="block text-sm font-medium text-gray-700 py-3">Ville</label>
            <input type="text" id="town" name="town" value={credentials.town} onChange={handleChange} className="mt-1 px-6 block w-full border border-gray-300 py-5 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.town && <span className="text-sm text-red-600">{errors.town}</span>}

            <label htmlFor="password" className="block text-sm font-medium text-gray-700 py-3 mt-6">Mot de passe</label>
            <input type="password" id="password" name="password" value={credentials.password} onChange={handleChange} className="mt-1 px-6 py-5 block w-full border border-gray-300 rounded-md focus:outline-none hover:border-gray-700 focus:border-gray-700" />
            {errors.password && <span className="text-sm text-red-600">{errors.password}</span>}

            <label className="block text-sm font-medium text-gray-700 py-3 mt-6">
              <input type="checkbox" name="agreeToTerms" checked={credentials.agreeToTerms} onChange={handleChange} className="mr-3" />
              J'ai lu et j'accepte les <a href="#" className="underline text-gray-900">termes et conditions d'achat et de vente</a>
            </label>
            {errors.agreeToTerms && <div className="text-sm text-red-600">{errors.agreeToTerms}</div>}

            <button type="submit" className="mt-4 px-4 py-2 bg-blue-500 text-white rounded w-full py-4 mt-4 submit" disabled={isLoading}>
              {isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : 'Inscription'}
            </button>

            <div className="text-center w-full mt-3">
              <Link to="/account#login" className="underline items-center mt-3">Vous avez déjà un compte ? Connectez-vous</Link>
            </div>
          </form>
        </div>
      </div>
    );
}

export default RegisterSeller;
