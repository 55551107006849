import { Link, useLocation } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';

const CheckEmail = () => {
    const location = useLocation();
    const email = location.state?.email || 'votre email';
    const fromLogin = location.state?.fromLogin;

    return (
        <div className="flex flex-col items-center justify-center my-2 py-1">
            <div className="flex flex-col items-center justify-center my-24 py-24">
                <div className="transition-transform duration-300 transform scale-100 flex flex-col items-center bg-white p-8 rounded-lg">
                    <div className="flex items-center justify-center w-32 h-32 rounded-full bg-green-100 mb-4 slide-in">
                        <CheckIcon className="h-24 w-24 text-green-500" />
                    </div>
                    <h2 className="text-3xl font-bold text-gray-800 mb-2 text-center">
                        {fromLogin ? 'Email non vérifié' : 'Bienvenue sur notre Deffci !'}
                    </h2>
                    <p className="text-gray-600 mb-4 text-center">
                        {fromLogin 
                            ? `Vous devez vérifier votre email ${email} avant de pouvoir vous connecter`
                            : `Nous avons envoyé un email de confirmation à ${email}`
                        }
                    </p>
                    <div className="flex items-center">
                        <div className="text-gray-600 ml-10 text-center">
                            Veuillez cliquer sur le lien dans l'email pour activer votre compte.
                        </div>
                    </div>
                    <p className="mt-5 text-sm text-gray-600">
                        Si vous ne trouvez pas l'email, vérifiez votre dossier spam.
                    </p>
                    <div className="mt-10">
                        <Link to="/account" className="text-blue-600 hover:text-blue-800">
                            Retour à la page de connexion
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckEmail; 