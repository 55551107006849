import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {get} from '../services/apiService';
import { HeartIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';
import ProductCard from '../components/ProductCard';
import { AuthContext } from '../contexts/AuthContext';
import { Helmet } from 'react-helmet-async';
import NotFoundPage from './NotFoundPage';

const Product = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);
  const [similars, setSimilars] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
	const [isLoading, setLoading] = useState(false);
	const [isLoadingNow, setLoadingNow] = useState(false);
	const [notFound, setNotFound] = useState(false);
  const [count, setCount] = useState(1);
  
  const {addToCart, setOpenCart} = useContext(AuthContext);

  const fetchProduct = async () => {
    try {
      const response = await get(`/product/${slug}`);
      setProduct(response.product);
      setSimilars(response.similars);
      setSelectedImage(response.product.medias[0]);
      return;
    }catch(error) {
      console.log("An error occured!");
      setNotFound(true);
    }
  }

  const addCart = async (product, count) => {
		setLoading(true);
		await addToCart(product, count);
		setLoading(false);
		setOpenCart(true);
  }

  useEffect(() => {
    fetchProduct();
  }, [slug]);

  const deliveryDate = (marge) => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + marge);
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + marge + 2);

    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startMonth = startDate.toLocaleDateString('fr-FR', { month: 'long' });
    const endMonth = endDate.toLocaleDateString('fr-FR', { month: 'long' });
    if (startMonth === endMonth) {
      return `${startDay} et ${endDay} ${startMonth}`;
    } else {
      return `${startDay} ${startMonth} et ${endDay} ${endMonth}`;
    }
  }

  const handleMinus = () => {
    if(count > 1)
      setCount(count-1)
  }

  if(notFound)
    return <NotFoundPage />
  return (
    <div className="mx-auto max-w-7xl">
      {product && (
        <Helmet>
          <title>{product.name} - Achetez maintenant sur DeffCi</title>
          <meta name="description" content={`Achetez ${product.name} au meilleur prix sur DeffCi. Livraison rapide au Sénégal.`} />
          <meta name="keywords" content={`${product.name}, acheter ${product.name}, Sénégal, e-commerce, livraison`} />
          
          <meta property="og:title" content={`${product.name} - Achetez maintenant sur DeffCi`} />
          <meta property="og:description" content={`Découvrez ${product.name} sur DeffCi. Prix compétitifs et livraison rapide au Sénégal.`} />
          <meta property="og:image" content={product.medias[0].url} />
          <meta property="og:url" content={`https://deffci.com/product/${product.slug}`} />
          <meta property="og:type" content="product" />
          
          <meta name="twitter:card" content={product.medias[0].url} />
          <meta name="twitter:title" content={`${product.name} - Achetez maintenant sur DeffCi`} />
          <meta name="twitter:description" content={`Achetez ${product.name} au meilleur prix sur DeffCi.`} />
          <meta name="twitter:image" content={product.medias[0].url} />
        </Helmet>
      )}
      {product && (<div className="mt-12">
        <div className="flex flex-wrap">
          <div className="lg:w-1/2 w-full">
            <div className="w-full p-4 flex">
              <div className="flex flex-col space-y-2">
                  {product.medias.map((image, index) => (
                  <img
                      key={index}
                      src={image.url}
                      alt={`Product ${index + 1}`}
                      className={`w-20 h-20 cursor-pointer transform transition duration-300 hover:scale-110 ${
                      selectedImage === image ? 'border-2 border-blue-500' : ''
                      }`}
                      onClick={() => setSelectedImage(image)}
                  />
                  ))}
              </div>
              <div className="w-full flex-auto p-4">
              <img
                  src={selectedImage.url}
                  alt="Selected Product"
                  className="w-full h-auto max-h-96 object-contain transform transition duration-300 hover:scale-110"
                  />
              </div>
            </div>
          </div>
        <div className="w-full lg:w-1/2 p-4 mt-10">
          <h1 className="text-3xl font-semibold">{product.name}</h1>
          <div className="text-sm">
            Catégorie: <Link className="pl-3" to="`/category/${category.slug}`"><span className="">{product.category.name}</span></Link>
          </div>
          {product.quantity > 0 && (<><div className="mt-10">
            <span className="text-sm">Prix: </span>
            <span className="text-5xl">{product.price}</span>
            &nbsp; fcfa
          </div>
          <div className="flex items-center mt-6 w-auto">
            <div disable={true} className="w-12 h-12 border-2 flex text-center justify-center items-center text-3xl mx-3 cursor-pointer hover:border-gray-900 transition duration-200"
            onClick={handleMinus}>-</div>
            <div className="w-12 h-12 text-3xl flex items-center text-center justify-center">{count}</div>
            <div className="w-12 h-12 border-2 flex text-center justify-center items-center text-3xl mx-3 cursor-pointer hover:border-gray-900 transition duration-200"
            onClick={() => setCount(count+1)}>+</div>
          </div>
          <div className="w-full flex justify-items-center">
            <button onClick={() => addCart(product, count)} className="text-white text-sm mt-4 w-1/2 py-4 m-1 rounded submit flex items-center justify-center space-x-2 text-center font-semibold">
              {isLoading ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : <><ShoppingCartIcon className="w-5 h-5 mr-2" /> Ajouter au panier</>}
            </button>
            <button onClick={() => addCart(product)} className="text-white text-sm mt-4 w-1/2 py-4 m-1 rounded submit-white flex items-center justify-center space-x-2 text-center font-semibold">
              {isLoadingNow ? <div className="flex justify-center items-center"><span className="spinner"></span></div> : 'Acheter maintenant'}
            </button>
          </div>
          <div className="mt-3">
            Livraison entre <span className="font-semibold">{deliveryDate(product.deliver)}</span>
          </div>
          </>)}
          
          {product.quantity == 0 && (<div className="mt-10 mb-6">
            Ce produit est en rupture de stock
          </div>)}

          {/* <div className="mt-6 mb-3 flex justify-end">
            <a href="#" className="block flex items-center text-gray-600">
              <HeartIcon className="w-6 h-6 mr-3" />
              Ajouter aux favoris</a>
          </div> */}
          
          <div className="w-full shadow px-10 py-4 flex flex-col">
            <span className="text-gray-500">Produit vendu par:</span>
            <span className="font-semibold">{product.shop.name}</span>
          </div>
        </div>
      </div>
			<div className="w-full lg:w-2/3 p-12" dangerouslySetInnerHTML={{ __html: product.description }} />
      {similars.length > 0 && (<div className="mt-10">
        <h2 className="text-xl font-semibold mb-4">Produits similaires</h2>
        <div className="flex flex-wrap">
          {similars.map((product) => (
            <ProductCard product={product} />
          ))}
        </div>
      </div>)}
    </div>)}
    </div>
  );
};

export default Product;
