import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiService from '../services/apiService';
import { AuthContext } from '../contexts/AuthContext';
import CheckAnimation from '../components/CheckAnimation';

const PaymentProcessing = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {setCartItems} = useContext(AuthContext);
  const [commandDone, setCommandDone] = useState(false);

  useEffect(() => {
    checkPayment();
  }, []);

  const checkPayment = async () => {
    try{
      await apiService.post('/payment-sucess', {id})
      localStorage.setItem('carts', JSON.stringify([]));
      setCartItems([]);
      setCommandDone(true);
    }catch(e){
      navigate('/checkout');
    }
  }

  return (
    <div className="mx-auto max-w-7xl">
        <div className="flex flex-col items-center min-h-screen mt-12">
          {commandDone
            ? <CheckAnimation />
            : <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md text-center">
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Merci pour votre paiement !</h2>
              <p className="text-gray-600 mb-6">Nous vérifions votre transaction. Cela peut prendre quelques instants.</p>
              <div className="flex justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
              </div>
              <p className="text-sm text-gray-500 mt-6">
                Veuillez ne pas actualiser ou quitter cette page.
              </p>
            </div>
            }
          </div>
    </div>
  );
};

export default PaymentProcessing;
