import React, { useState, useEffect } from 'react';
import {post} from '../services/apiService';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import { AdjustmentsHorizontalIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet-async';

function Search() {
  const {category} = useParams();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [prices, setPrices] = useState({
    minPrice: null,
    maxPrice: null,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const fetchProducts = async () => {
    setLoading(true);
    try{
      let searchTerms = {};

      if(prices.minPrice !== null && prices.minPrice !== "")
        searchTerms.min_price = prices.minPrice;
      
      if(prices.maxPrice !== null && prices.maxPrice !== "")
        searchTerms.max_price = prices.maxPrice;

      if(searchParams.get('q'))
        searchTerms.q = searchParams.get('q');

      if(searchParams.get('category') !== undefined || category !== undefined)
        searchTerms.category = searchParams.get('category') || category;
      
      const response = await post('/search', searchTerms);
      setProducts(response.products);
      setCategories(response.categories);
      return;
    }catch(error){
      console.log("An error occured!", error);
    }finally{
      setLoading(false);
    }
  }

  const handleCategory = (e, category) => {
    e.preventDefault();
    const newSearchParams = new URLSearchParams(searchParams.toString());

    newSearchParams.set('category', category);
    setIsOpen(false);

    navigate(`/search?${newSearchParams.toString()}`);
  };

  const handlePrices = e => {
    e.preventDefault();

    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('min_price');
    if(prices.minPrice !== null && prices.minPrice !== ""){
      newSearchParams.set('min_price', prices.minPrice);
    }
    newSearchParams.delete('max_price');
    if(prices.maxPrice !== null && prices.maxPrice !== ""){
      newSearchParams.set('max_price', prices.maxPrice);
    }
    setIsOpen(false);
    navigate(`/search?${newSearchParams.toString()}`);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPrices((prevPrices) => ({
      ...prevPrices,
      [name]: value,
    }));
  };

  const removeFilter = (type) => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete(type);
    if(type == 'prices'){
      newSearchParams.delete('min_price');
      newSearchParams.delete('max_price');
      setPrices({minPrice: "", maxPrice: ""})
    }
    setIsOpen(false);
    navigate(`/search?${newSearchParams.toString()}`);
  }

  useEffect(() => {
    fetchProducts();
  }, [category, searchParams]);

  const [isOpen, setIsOpen] = useState(false);

  const getSearchQuery = () => {
    if(searchParams.get('q') == null && category == undefined)
      return "";
    return searchParams.get('q') != null ? searchParams.get('q') : undefined;
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div class="container mx-auto max-w-7xl py-5 mt-6 flex">
      <Helmet>
        <title>Résultats pour "{getSearchQuery() ?? category}" - DeffCi</title>
        <meta name="description" content={`Découvrez les résultats pour "${getSearchQuery() ?? category}" sur DeffCi. Trouvez les meilleurs produits correspondant à votre recherche.`} />
        <meta name="keywords" content={`${getSearchQuery() ?? category}, achat en ligne, e-commerce, Sénégal, DeffCi`} />
        
        <meta property="og:title" content={`Résultats pour "${getSearchQuery() ?? category}" - DeffCi`} />
        <meta property="og:description" content={`Explorez notre sélection de produits correspondant à "${getSearchQuery() ?? category}" sur DeffCi.`} />
        <meta property="og:url" content={getSearchQuery() ? `https://deffci.com/search?q=${encodeURIComponent(getSearchQuery())}` : `https://deffci.com/category/${category}`} />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="https://deffci.com/logo.jpg" />
        <meta name="twitter:title" content={`Résultats pour "${getSearchQuery() ?? category}" - DeffCi`} />
        <meta name="twitter:description" content={`Découvrez les meilleurs produits pour "${getSearchQuery() ?? category}" sur DeffCi.`} />
      </Helmet>
      <div>
        <div
          className={`fixed inset-0 z-50 lg:z-10 w-80 transform lg:transform-none lg:relative lg:translate-x-0 transition-transform duration-200 bg-gray-800 ease-in-out p-0 ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          } lg:translate-x-0 lg:block`}
        >
          <div class="h-full bg-white p-5 border-r">
            <div className="mb-4">
              <div className="flex items-center justify-between">
                <h2 class="font-semibold text-lg">Filtres</h2>
                <button
                  onClick={toggleSidebar}
                  className="p-2 lg:hidden"
                >
                  <XMarkIcon className="w-5" />
                </button>
              </div>
              <ul>
                {searchParams.get('category') && (
                  <li className="rounded bg-gray-600 text-white text-xs my-1 px-2 py-1 flex justify-between">
                    Catégorie: 
                    <span className="font-semibold flex-auto">{searchParams.get('category')}</span>
                    <span className="text-right cursor-pointer font-bold" onClick={() => removeFilter('category')}>X</span>
                  </li>
                )}
                
                {((prices.minPrice != null && prices.minPrice != "") || (prices.maxPrice != null && prices.maxPrice != "")) && (
                  <li className="rounded bg-gray-600 text-white text-xs my-1 px-2 py-1 flex justify-between">
                    Prix: 
                    <span className="font-semibold flex-auto">
                    {prices.minPrice || '0'} - {prices.maxPrice || '+'}
                    </span>
                    <span className="text-right cursor-pointer font-bold" onClick={() => removeFilter('prices')}>X</span>
                  </li>
                )}
              </ul>
            </div>
            <div class="mb-6">
              <h3 class="font-semibold mb-2">Catégories</h3>
              <ul class="flex flex-col">
                {categories && categories.map((category, index) =>
                <li key={index}>
                  <a href='#'  onClick={e => handleCategory(e, category.slug)} class="flex items-center p-2 hover:text-blue-500">
                    {category.name}
                  </a>
                </li>
                )}
                {/* <li>
                  <a href="#electronics" class="flex items-center p-2 hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                    Électronique
                  </a>
                </li>
                <li>
                  <a href="#clothing" class="flex items-center p-2 hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16V4a2 2 0 012-2h12a2 2 0 012 2v12l-8 5-8-5z" />
                    </svg>
                    Vêtements
                  </a>
                </li>
                <li>
                  <a href="#home" class="flex items-center p-2 hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-1a1 1 0 001-1V10m-5 5h.01M21 12l-2-2m-3 3a1 1 0 011-1h3m4 0a1 1 0 011 1v10a1 1 0 01-1 1h-3m-10 0H5a1 1 0 01-1-1V10m0 0L3 12m18 0l2 2m-2-2a9 9 0 01-18 0"/>
                    </svg>
                    Maison
                  </a>
                </li>
                <li>
                  <a href="#books" class="flex items-center p-2 hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6m6-6a2 2 0 100 4h6a2 2 0 110 4H6a2 2 0 100 4h12a2 2 0 110 4H6a2 2 0 100 4h12a2 2 0 010-4H6"/>
                    </svg>
                    Livres
                  </a>
                </li>
                <li>
                  <a href="#sports" class="flex items-center p-2 hover:text-blue-500">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 mr-2">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.55-3.27a1 1 0 011.45 1.02v6.5a1 1 0 01-1.45 1.02L15 13v5a1 1 0 01-.7.96l-6 2A1 1 0 017 21V6a1 1 0 011.3-.97l5.7 1.9" />
                    </svg>
                    Sports et Loisirs
                  </a>
                </li> */}
              </ul>
            </div>
            <div class="mb-6">
              <h3 class="font-semibold mb-2">Prix</h3>
              <div className="grid grid-cols-3">
                <input type="text" name="minPrice" value={prices.minPrice} onChange={handleChange} className="w-20 border-2 border-gray-400 p-2 mr-3 outline-none hover:border-gray-800 focus:border-gray-800 text-center" placeholder="Min" />
                <input type="text" name="maxPrice" value={prices.maxPrice} onChange={handleChange} className="w-20 border-2 border-gray-400 p-2 mr-3 outline-none hover:border-gray-800 focus:border-gray-800 text-center" placeholder="Max" />
                <button type="submit" onClick={handlePrices} className="w-20 border border-gray-400 p-2 mr-3 bg-gray-900 text-white font-semibold">Go</button>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (<div className="fixed top-0 right-0 w-full h-full bg-black bg-opacity-50 z-20 transform duration-500 ease-in-out" onClick={toggleSidebar} />)}
      </div>

      <div className="flex flex-col w-full">
        <div className="relative bg-cover bg-center bg-no-repeat h-36 m-3" style={{ backgroundImage: `url('/search_bg.jpg')` }}>
          <div className="absolute top-1/2 left-8 transform -translate-y-1/2 text-orange-600 text-xl font-bold w-1/3">
            Résultats de la recherche
          </div>
        </div>
        <div className="w-full">
          <div className="m-3 lg:hidden">
            <button className="flex px-12 py-1 border-2 border-slate-400 rounded items-center" onClick={toggleSidebar}>
              <AdjustmentsHorizontalIcon className="w-4" />
              <span className="px-3 py-2">Filtres</span>
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center mt-4">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="m-3 bg-blue-100 font-bold text-blue-600 p-3 rounded">Aucun produit ne correspond à votre recherche</div>
          )}

          {products.length > 0 && (<div className="grid lg:grid-cols-3 grid-cols-2">
              { products.map((product, index) => 
                <ProductCard product={product} key={index} />
              // <div class="flex flex-col p-6 lg:px-8 relative w-1/3" key={index}>
              //   <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
              //     <img src={product.image} alt="Front of men&#039;s Basic Tee in black." class="h-full w-full object-cover object-center lg:h-full lg:w-full" />
              //   </div>
              //   <div class="mt-4 flex justify-between">
              //     <div>
              //       <h3 class="text-sm text-gray-700">
              //         <a href="#">
              //           <span aria-hidden="true" class="absolute inset-0"></span>
              //           Basic Tee
              //         </a>
              //       </h3>
              //       <p class="mt-1 text-sm text-gray-500">Black</p>
              //     </div>
              //     <p class="text-sm font-medium text-gray-900 justify-self-end">$35</p>
              //   </div>
              // </div>
            )}
          </div>)}
        </div>
      </div>
    </div>
  );
}

export default Search;
