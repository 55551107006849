import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import {get as fetchData, put} from '../../services/apiService';

const ManageOrders = () => {

  const [orders, setOrders] = useState([]);
  useEffect(() => {
    fetchOrders();
  }, []);

  const orderStatuses = {
    0: 'En attente',
    1: 'Confirmé',
    2: 'Expédié',
    3: 'Livré',
    5: 'Annulé'
  }

  const fetchOrders = async () => {
    try{
      const response = await fetchData('/orders');
      setOrders(response.orders);
    }catch(e){
      console.log('Une erreur est survenue');
    }
  }

  const handleStatusChange = async (orderlineId, newStatus) => {
    if(window.confirm("Voulez-vous changer le statut: " + orderStatuses[newStatus])) {
      setOrders(prevOrders =>
        prevOrders.map(order => ({
          ...order,
          orderlines: order.orderlines.map(orderline =>
            orderline.id === orderlineId ? { ...orderline, status: newStatus } : orderline
          )
        }))
      );
  
      try{
        await put('/orders', { orderlineId, status: newStatus })
      }catch(e){
        console.log('Une erreur est survenue');
      }
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div class="container mx-auto max-w-7xl py-5 mt-6 flex">
        <Sidebar />
        <div class="flex flex-col w-full mr-3 bg-white p-3">
          <div class="flex justify-end mb-4">
            <div class="overflow-x-auto w-full">
              <div class="min-w-full py-2 align-middle inline-block">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"></div>
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID Commande</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Produit</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Prix</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quantité</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Statut</th>
                    </tr>
                  </thead>
                  <tbody class="bg-white divide-y divide-gray-200">
                    {orders.map((order, row) => (
                      order.orderlines.map((orderline, index) => (
                        <tr key={index}>
                          {index === 0 && (<td class="px-6 py-4 whitespace-nowrap" rowSpan={order.orderlines.length}>
                            <div class="text-sm font-medium text-gray-900">
                                #{order.id}
                            </div>
                          </td>)}
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-500">{orderline.product.name}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-500">{orderline.product.price}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-500">{orderline.qty}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <div class="text-sm text-gray-500">{order.infos.firstName} {order.infos.lastName}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-nowrap">
                            <select
                              value={orderline.status}
                              onChange={(e) => handleStatusChange(orderline.id, e.target.value)}
                              className="border rounded p-1 bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            >
                              {Object.entries(orderStatuses).map(([key, value]) => (
                                <option key={key} value={key}>{value}</option>
                              ))}
                            </select>
                          </td>
                        </tr>
                      ))
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageOrders;
