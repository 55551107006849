import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';


function PrivateRoute({ children }) {
  const {authUser} = JSON.parse(localStorage.getItem('authUser'));
  const isAuthenticated = authUser !== null;
  // console.log(isAuthenticated)

  return isAuthenticated ? children : <Navigate to="/account" />;
}

export default PrivateRoute;
