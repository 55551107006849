import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../services/apiService';

const VerifyEmail = () => {
    const [message, setMessage] = useState('Vérification de votre email en cours...');
    const navigate = useNavigate();

    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');
        
        if (!token) {
            setMessage('Lien de vérification invalide');
            return;
        }

        post('/email/verify', { token })
            .then(() => {
                setMessage('Email vérifié avec succès ! Redirection...');
                setTimeout(() => navigate('/account'), 2000);
            })
            .catch((error) => {
                setMessage(error.response?.data?.message || 'Une erreur est survenue lors de la vérification');
            });
    }, [navigate]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            padding: '20px'
        }}>
            <div>
                <h2>Vérification Email</h2>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default VerifyEmail; 