import React, { createContext, useContext, useState, useEffect } from 'react';
import { get as fetchData, post } from '../services/apiService';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);
  const [categories, setCategories] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [openCart, setOpenCart] = useState(false);

  const addToCart = async (product, qty = 1) => {
    try{
      if(authUser){
        const response = await post('/cart/add', {productId: product.id, quantity: qty});
        setCartItems(response.carts);
        localStorage.setItem('carts', JSON.stringify(response.carts));
      }else{
        let carts = JSON.parse(localStorage.getItem('carts')) || [];
        const existingProductIndex = carts.findIndex(cartItem => cartItem.product.id === product.id);

        if (existingProductIndex !== -1) {
            carts[existingProductIndex].quantity += qty;
            if(carts[existingProductIndex].quantity <= 0)
              carts.splice(existingProductIndex, 1);
        } else {
            carts.push({ product: product, quantity: 1 });
        }
        localStorage.setItem('carts', JSON.stringify(carts));
        setCartItems(carts);
      }
    }catch(error){
      console.log("Erreur");
    }
  };

  const removeCartItem = async (cart) => {
    try {
      if(authUser){
        const response = await post('/cart/remove', {productId: cart.product.id});
        setCartItems(response.carts);
        localStorage.setItem('carts', JSON.stringify(response.carts));
      }else{
        let carts = JSON.parse(localStorage.getItem('carts')) || [];
        const existingProductIndex = carts.findIndex(cartItem => cartItem.product.id === cart.product.id);

        if (existingProductIndex !== -1) {
          carts.splice(existingProductIndex, 1);
        }
        localStorage.setItem('carts', JSON.stringify(carts));
        setCartItems(carts);
      }
    }catch(error){
      console.log("Erreur");
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const user = JSON.parse(localStorage.getItem('authUser'));
    const carts = JSON.parse(localStorage.getItem('carts'));
    if (token) {
      setAuthUser({ token });
    }
    if(user) {
      setAuthUser({ ...user });
    }
    if(carts) {
      setCartItems(carts);
    } else {
      fetchCarts();
    }
    const cat = JSON.parse(localStorage.getItem('categories'));
    if(cat)
      setCategories(cat);
    else
      fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const data = await fetchData('/categories');
      setCategories(data.categories);
      localStorage.setItem('categories', JSON.stringify(data.categories));
    }catch(e){
      console.error("Error fetching categories")
    }
  }

  const fetchCarts = async (force = false) => {
    try{
      if(force || authUser) {
        try{
          const response = await fetchData('/cart/get');
          localStorage.setItem('carts', JSON.stringify(response.carts));
          setCartItems(response.carts);
        }catch(e) {
          console.log("Une erreur est survenue");
        }
      }else{
        let carts = JSON.parse(localStorage.getItem('carts')) || [];
        setCartItems(carts);
      }
    }catch(e){
      console.error("Error fetching cart")
    }
  }

  const logout = async () => {
    try {
      await post('/logout', {authToken: localStorage.getItem('accessToken')});
      localStorage.removeItem('accessToken');
      localStorage.removeItem('authUser');
      setAuthUser(null);
      localStorage.removeItem('carts');
    }catch(e) {
      console.log("Une erreur est survenue lors de la déconnexion")
    }
  };

  return (
    <AuthContext.Provider value={{ authUser, categories, logout, addToCart, cartItems, setCartItems, removeCartItem, openCart, setOpenCart, fetchCarts }}>
      {children}
    </AuthContext.Provider>
  );
};
