import React, { useEffect, useState } from 'react';
import ProductCard from '../components/ProductCard';
import { Helmet } from 'react-helmet-async';
import { get as fetchData } from '../services/apiService';

function Home() {
  const images = [
    '/slider01.webp',
    '/google_logo.png',
    '/slider01.webp',
  ];
  const [products, setProducts] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetchData('/products/featured');
      setProducts(response.products);
    } catch (e) {
      console.log('Une erreur est survenue');
    }
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + images.length) % images.length);
  };

  return (
    <div className="mx-auto max-w-7xl">
      <Helmet>
        <title>DeffCi - Votre e-commerce de confiance au Sénégal</title>
        <meta name="description" content="Découvrez DeffCi, votre plateforme e-commerce de confiance au Sénégal. Achetez en toute sécurité une large gamme de produits, livrés directement chez vous." />
        <meta name="keywords" content="e-commerce, Sénégal, achat en ligne, livraison, produits, sécurité, confiance" />
        
        <meta property="og:title" content="DeffCi - Votre e-commerce de confiance au Sénégal" />
        <meta property="og:description" content="Découvrez une large gamme de produits sur DeffCi, votre plateforme e-commerce de confiance au Sénégal. Livraisons rapides et sécurisées." />
        <meta property="og:image" content="https://deffci.com/logo.jpg" />
        <meta property="og:url" content="https://deffci.com" />
        <meta property="og:type" content="website" />
        
        <meta name="twitter:card" content="https://deffci.com/logo.jpg" />
        <meta name="twitter:title" content="DeffCi - Votre e-commerce de confiance au Sénégal" />
        <meta name="twitter:description" content="Achetez en toute sécurité une large gamme de produits sur DeffCi. Livraison rapide et sécurisée au Sénégal." />
        <meta name="twitter:image" content="https://deffci.com/logo.jpg" />
      </Helmet>
      <div className="flex mt-3">
        <div className="flex">
          <div className="lg:w-80">&nbsp;</div>
        </div>
        <div className="flex-auto">
          <div className="relative">
            <img src={images[currentSlide]} alt={`Slide ${currentSlide}`} className="w-full" />
            <button onClick={prevSlide} className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1">&#10094;</button>
            <button onClick={nextSlide} className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1">&#10095;</button>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="text-xl">Top des ventes</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 px-5 py-2">
          {products.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;